import React, { PureComponent } from 'react'
import Helmet from 'react-helmet'

export default class SEO extends PureComponent {
  render() {
    const { title, siteTitle, image, description, url, imageWidth, imageHeight, customMetaTags } = this.props;

    return (
      <Helmet title={`${title} • ${siteTitle}`}>
        {title && <meta name="title" content={title} />}
        {url && <meta property="og:url" content={url} />}
        <meta property="og:site_name" content={siteTitle}/>
        {description && <meta property="og:description" content={description} />}
        {description && <meta name="description" content={description} />}
        {image && <meta name="image" content={image} />}
        {title && <meta property="og:title" content={title} />}
        {image && <meta property="og:image" content={image} />}
        {image && <meta property="twitter:image" content={image} />}
        {image && <meta property="og:image:alt" content={title} />}
        {image && <meta property="og:image:width" content={imageWidth || 1232} />}
        {image && <meta property="og:image:height" content={imageHeight || 613} />}
        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:card" content="summary" />
        <meta name="twitter:site" content="@_LevelStudios" />
        <meta name="twitter:creator" content="@_LevelStudios" />
        {/* <meta name="twitter:url" content="" /> */}
        {title && <meta property="twitter:title" content={title} />}
        {description && <meta name="twitter:description" content={description} />}
        {customMetaTags.map(({ name, content }, i) => <meta key={i} name={name} content={content} />)}
      </Helmet>
    )
  }
}

SEO.defaultProps = {
  title: 'Web and Mobile Design',
  siteTitle: 'Level Studios Design',
  customMetaTags: [],
}
