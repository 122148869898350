import styled from 'styled-components'
import { space, textAlign } from 'styled-system'
import themeGet from '@styled-system/theme-get'

const FooterWrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 1.5rem;
  width: 100%;

  ${space} ${textAlign};
`

export default FooterWrapper
