import React, { Fragment, useContext } from "react"
import { StaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import get from "lodash/get"

import { default as Theme, ThemeContext } from "../Theme"
import themes from '../../styles/theme'

import SEO from "../SEO"
import Page, { Content } from "../Page"
import Header from "../Header"
import Footer from "../Footer"

const Layout = props => {
	const theme = themes[useContext(ThemeContext).theme]
  const { location, children } = props

  const siteUrl = get(props, "data.site.siteMetadata.siteUrl")
  const siteTitle = get(props, "data.site.siteMetadata.title")
  const siteDescription = get(props, "data.site.siteMetadata.description")
  const defaultCover =
    siteUrl + get(props, "data.defaultCover.childImageSharp.fixed.src")
  const defaultSocialMedia =
    siteUrl + get(props, "data.socialMediaDefault.childImageSharp.fixed.src")
  const author = get(props, "data.site.siteMetadata.author")
  const email = get(props, "data.site.siteMetadata.email")
  const keywords = get(props, "data.site.siteMetadata.keywords")
  const content = children
  const url = siteUrl + location.pathname
  const meta = {
    title: "Level Studios Design - Creative Digital Agency",
    description: siteDescription,
    image: defaultSocialMedia,
    defaultImage: defaultCover,
    siteTitle: siteTitle,
    imageWidth: get(props, "data.socialMediaDefault.childImageSharp.fixed.width"),
    imageHeight: get(props, "data.socialMediaDefault.childImageSharp.fixed.height"),
};
  return (
    <Fragment>
      <Helmet>
        <meta name="theme-color" content={theme.colors.base} />
				<meta name="theme-color" content={theme.colors.base} />
				<meta name="msapplication-navbutton-color" content={theme.colors.base} />
				<meta name="apple-mobile-web-app-capable" content="yes" />
				<meta name="apple-mobile-web-app-status-bar-style" content={theme.colors.base} />
        <meta name="author" content={`${author}, ${email}`} />
        <meta name="keywords" content={keywords.join(", ")} />
        <meta name="robots" content="index, follow" />
  	    <meta name="title" content={siteTitle} />
        <meta name="description" content={siteDescription} />
        <meta property="og:site_name" content={siteTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta
          name="google-site-verification"
          content="9cNJedFf6ohyeIWxHsI1y5-DBAJUk7AoJ7kU-9EqPvo"
        />
        <link rel="canonical" href={url} />
        <link rel="stylesheet" href={`/css/syntax-${theme.name}.css`} />
      </Helmet>
      <SEO {...meta} url={siteUrl} />
      <Page>
        <Header />
        <Content>{content}</Content>
        <Footer />
      </Page>
    </Fragment>
  )
}

export default ({ location, history, children }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
            author
            email
            description
            siteUrl
            keywords
          }
        }
        defaultCover: file(relativePath: { eq: "img/avatar.png" }) {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      socialMediaDefault: file(relativePath: { eq: "img/socialmediadefault.png" }) {
              childImageSharp {
                fixed(
                  width: 1200
                  height: 630
                ) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
    `}
    render={data => (
      <Theme>
        <Layout {...{ location, history, children, data }} />
      </Theme>
    )}
  />
)
